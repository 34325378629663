.signInSheet {
  width: 300px;
  margin: 0 auto;
  padding: 24px;
  margin-top: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}