.sizeNaviContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;
}

.sideNaviHeader {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 0 10px auto;
}

.sideNaviBody {
  flex: 1;
  overflow-y: scroll;
}

.sideNaviFooter {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}

.userInfo {
  overflow-x: scroll;
}