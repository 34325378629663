:root {
  --white: #FFFFFF;
  --gray75: #FAFAFA;
  --gray100: #F5F5F5;
  --gray200: #EAEAEA;
  --gray300: #E1E1E1;
  --gray400: #cccccc;
  --gray500: #B3B3B3;
  --gray600: #8E8E8E;
  --gray700: #6E6E6E;
  --gray800: #4B4B4B;
  --gray900: #2C2C2C;
  --black: #000000;
}