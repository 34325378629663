$appBarHeight: 52px;

.appBar {
  position: sticky;
  z-index: 100;
  top: 0;
  height: $appBarHeight;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  box-shadow: var(--joy-shadow-sm);
  background-color: var(--joy-palette-background-surface);

  h3 {
    font-size: 14px;
  }
}

.appBarBuffer {
  height: $appBarHeight;
}